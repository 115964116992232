import { lazy } from 'react';
import { PATHS } from './constants';

const Home = lazy(() => import('./screens/main'));

export default [
  {
    component: Home,
    path: PATHS.BASE_URL,
    onOrgChangeRedirectTo: `/:orgId/-${PATHS.BASE_URL}`,
  },
];
