import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Spin } from 'antd';

import { actions } from 'packages/divisions';
import IntlMessages from 'util/IntlMessages';

import { TAB_SIZE } from '../../../../constants/ThemeSetting';

const { Option } = Select;
const ALL_DIVS = 'all';

const DivisionSelector = () => {
  const dispatch = useDispatch();

  const selectedDivs = useSelector(({ divisions }) => divisions.selector.ids || []);
  const { width } = useSelector(({ common }) => common);
  const { id: selectedOrgId, loading: orgLoading } = useSelector(
    ({ organizations }) => organizations.organization,
  );
  const userDivisions = useSelector(({ user }) =>
    Object.values(user.profile.data?.organizations[selectedOrgId]?.divisions || {}),
  );

  // did user delected all the divisions
  const allSelected = () => selectedDivs.length === allDivsIds.length;

  const onChange = value => {
    let divs = [];
    const lastValue = [...value].pop();

    if (lastValue === ALL_DIVS) {
      divs = allDivsIds;
    } else {
      // Unselect the "All divs" option
      const divValues = value.filter(i => i !== ALL_DIVS);
      // if user unselected all, then select all by default
      divs = !!value.length ? divValues : allDivsIds;
    }

    dispatch(actions.divisionsSelect(divs));
  };

  const allDivsIds = userDivisions.map(div => div.id);

  // On mount effect
  useEffect(() => {
    // filter(Boolean) it to remove the [''] in the array in case the selectedDivs item is empty
    const savedDivs = localStorage.getItem('selectedDivs')?.split(',').filter(Boolean) || [];

    // I check if they still exist because if the organization changed they must change.
    const prevDivsExist = savedDivs.every(savedDiv => allDivsIds.includes(savedDiv));

    const divs = !!savedDivs.length && prevDivsExist ? savedDivs : allDivsIds;
    dispatch(actions.divisionsSelect(divs));
  }, [selectedOrgId]);

  const sortDivisions = (a, b) =>
    a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });

  const options = userDivisions.sort(sortDivisions).map(div => (
    <Option key={div.id} value={div.id}>
      {div.name}
    </Option>
  ));

  const isResponsive = width < TAB_SIZE;

  return orgLoading ? (
    <Spin />
  ) : (
    <Select
      className="gx-m-0"
      dropdownMatchSelectWidth={false}
      maxTagCount={!isResponsive ? 2 : allSelected() ? 1 : 0}
      mode="multiple"
      onChange={onChange}
      value={allSelected() ? [ALL_DIVS] : selectedDivs}
      bordered={!isResponsive}
      showArrow={!isResponsive}
      allowClear={!isResponsive}
    >
      <Option key={ALL_DIVS} value={ALL_DIVS}>
        <IntlMessages id="divisionSelector.allDivisions" />
      </Option>
      {options}
    </Select>
  );
};

export default DivisionSelector;
