import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Drawer, Empty, Form, Grid, notification, Select, Spin } from 'antd';

import { db } from 'firebase/firebase';
import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import { errorNotification } from 'appRedux/actions';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import BoardForm from 'packages/dashboard/components/BoardForm';
import SlidePanel from 'packages/dashboard/components/SlidePanel';
import useGetEventTypes from 'packages/utils/hooks/useGetEventTypes';
import { collection, doc, query, serverTimestamp, writeBatch } from 'firebase/firestore';
import { ALLOWED_ROLES, useFirestoreQuery, useFirestoreRealtimeBatched } from 'packages/utils';
import { SlidePanelContext } from 'packages/dashboard/components/SlidePanel/SlidePanelContext';

import styles from './styles.module.less';
import ChartCard from './components/ChartCard';
import TableForm from './components/TableForm';
import TableCard from './components/TableCard';
import ChartEdit from './components/ChartEdit';
import EventsForm from './components/EventsForm';
import { get, set } from '../../../utils/storage';
import ChartFilters from './components/ChartFilters';
import { EventsByScheduleContext } from './EventsByScheduleContext';
import { onChartForm, onDeleteTable, onTableForm } from './formHandlers';
import {
  GROUP_BY_DEFAULT,
  ONE_WEEK_BACK,
  RELATIVE_DATE_PER_DEFAULT,
  TODAY_DATE,
} from './components/ChartFilters/constants';
import {
  COMPONENTS_OF_METRICS,
  COMPONENTS_OF_METRICS_OPTIONS,
  DASHBOARDS_COLLECTION,
  DASHBOARDS_EVENT_TYPE,
  DASHBOARDS_FORMS,
  makeSubtitle,
  METRICS_FILTER,
} from './constants';

const ResponsiveGridLayout = WidthProvider(Responsive);
const { useBreakpoint } = Grid;

const EventsDashboard = () => {
  const chartFormRef = useRef(null);
  const tableFormRef = useRef(null);
  const tableElementsRef = useRef({});

  const intl = useIntl();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const {
    boardSelected,
    onDeleteBoard,
    onOpenBoardFormEdit,
    isOpen: slidePanelOpened,
  } = useContext(SlidePanelContext);
  const hasBoardSelected = !!boardSelected.id;
  const isMobile = screens.xs;
  const orgId = useSelector(state => state.organizations.organization.id);
  const metricsFilterLastTime = get(METRICS_FILTER);
  const initFilters = metricsFilterLastTime || {
    relative: RELATIVE_DATE_PER_DEFAULT,
    dateRange: [ONE_WEEK_BACK, TODAY_DATE],
    groupBy: GROUP_BY_DEFAULT,
  };

  const { runGetAllSchedules } = useContext(EventsByScheduleContext);

  const [loading, setLoading] = useState(false);
  const [creatingChart, setCreatingChart] = useState(false);
  const [layoutChanges, setLayoutChanges] = useState({});
  const [savingLayoutChanges, setSavingLayoutChanges] = useState(false);
  const [refreshChart, setRefreshChart] = useState(false); // state to refresh each element into responsive grid
  const [filters, setFilters] = useState(initFilters);
  const [metricsComponenteSelected, setMetricsComponenteSelected] = useState(null);
  const currentElementSelected = useRef({
    chart: null,
    table: null,
  });

  const someChartTypeSelected = Object.values(currentElementSelected.current).some(
    val => val !== null,
  );

  const hasChanges = !!Object.values(layoutChanges)?.length;

  const componentsCollectionRef = {
    charts: `organizations/${orgId}/${DASHBOARDS_COLLECTION}/${boardSelected.id}/charts`,
    tables: `organizations/${orgId}/${DASHBOARDS_COLLECTION}/${boardSelected.id}/tables`,
  };
  if (boardSelected.divId) {
    componentsCollectionRef.charts = `organizations/${orgId}/divisions/${boardSelected.divId}/${DASHBOARDS_COLLECTION}/${boardSelected.id}/charts`;
    componentsCollectionRef.tables = `organizations/${orgId}/divisions/${boardSelected.divId}/${DASHBOARDS_COLLECTION}/${boardSelected.id}/tables`;
  }
  //  CHARTS
  const { data: charts = [], loading: chartsLoading } = useFirestoreQuery(
    collection(db, componentsCollectionRef.charts),
    [orgId, boardSelected.id],
  );
  // TABLES
  const tableRef = [query(collection(db, componentsCollectionRef.tables))];
  const { data: tables = [], loading: tablesLoading } = useFirestoreRealtimeBatched(tableRef, [
    orgId,
    refreshChart,
    boardSelected.id,
  ]);

  const { data: eventTypes, loading: eventLoading } = useGetEventTypes();

  const filtersMemo = useMemo(() => {
    // NOTE: If there is a filter saved in the local storage, it will be used
    // if not the default filter will be used, default value is from firestore (dashboard > queryparams)
    let output = filters;

    if (metricsFilterLastTime) return metricsFilterLastTime;

    if (boardSelected && boardSelected?.queryParams && boardSelected?.queryParams?.interval) {
      const {
        queryParams: {
          interval,
          range: { dateRange, isRelative, relativeAmount, dateType },
        },
      } = boardSelected || {};
      output = {
        relative: {
          isRelative,
          dateType,
          relativeAmount,
        },
        dateRange: [dateRange[0].toDate(), dateRange[1].toDate()],
        groupBy: interval,
      };
    }

    return output;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardSelected.queryParams, filters, metricsFilterLastTime]);

  const mainLoading = chartsLoading || tablesLoading || eventLoading;

  const checkLayoutChange = currentLayout => {
    const originalLayouts = {
      [DASHBOARDS_EVENT_TYPE.CHARTS]: Object.assign({}, ...charts.map(x => ({ [x.id]: x.layout }))),
      [DASHBOARDS_EVENT_TYPE.TABLES]: Object.assign({}, ...tables.map(x => ({ [x.id]: x.layout }))),
    };

    const newChanges = currentLayout.reduce((acc, { i: id, x, y, w, h }) => {
      const origin = Object.keys(originalLayouts).find(type => originalLayouts[type][id]);

      if (origin) {
        const {
          x: originalX,
          y: originalY,
          w: originalW,
          h: originalH,
        } = originalLayouts[origin][id];
        const layoutChanged =
          originalX !== x || originalY !== y || originalW !== w || originalH !== h;

        if (layoutChanged) {
          acc[id] = { x, y, w, h, id, origin };
        }
      }

      return acc;
    }, {});

    setLayoutChanges(newChanges);
  };

  const saveLayout = async () => {
    /** *
     * 1. Check if there are changes
     * 2. Get the list of changes
     * 3. Create a batch
     * 4. Update each element with the new layout
     * NOTE: origin is the type of the element (charts, tables, etc..)
     */

    const changesList = Object.values(layoutChanges);
    if (!hasChanges) {
      return;
    }

    const batch = writeBatch(db);
    changesList.forEach(({ id, x, y, w, h, origin }) => {
      batch.update(doc(db, `${componentsCollectionRef[origin]}/${id}`), {
        layout: { x, y, w, h },
        updatedAt: serverTimestamp(),
      });
    });
    setSavingLayoutChanges(true);
    try {
      await batch.commit();
    } catch (e) {
      dispatch(errorNotification(e.message || e));
    }
    setSavingLayoutChanges(false);
    setLayoutChanges({});
  };

  const onRefreshCharts = useCallback(
    refresh => {
      if (refresh) setRefreshChart(!refreshChart);
    },
    [refreshChart],
  );

  const handleFilters = e => {
    const { value, name } = e;
    setFilters(prev => {
      const newFilters = {
        ...prev,
        [name]: value,
      };
      // Save into localStorage
      set(METRICS_FILTER, newFilters);
      return newFilters;
    });
  };

  const onCloseMainDrawer = () => {
    setCreatingChart(false);
    setMetricsComponenteSelected(null);
    // Reset the current element selected
    currentElementSelected.current = {
      chart: null,
      table: null,
    };
  };

  // NOTE: elemntId is the id of the element that is being edited and type is the type of the element (Charts, Tables, etc..)
  const onEdit = (elementId, type) => {
    setCreatingChart(true);
    setMetricsComponenteSelected(type);
    currentElementSelected.current[type] = elementId;
  };

  const onFormFinish = useCallback(
    (name, { values }) => {
      if (!boardSelected.id) throw new Error('No board selected');

      const action = {
        [DASHBOARDS_FORMS.CHART]: () => {
          setLoading(true);

          onChartForm({
            data: values,
            orgId,
            divId: boardSelected?.divId,
            boardId: boardSelected.id,
          })
            .then(() => {
              notification.success({
                message: intl.formatMessage({ id: 'general.save.successful.message' }),
              });
              onRefreshCharts(true);
              onCloseMainDrawer();
            })
            .finally(() => {
              setLoading(false);
            });
        },
        [DASHBOARDS_FORMS.TABLE]: () => {
          setLoading(true);

          onTableForm({
            data: {
              ...values.tableFormData,
              id: values?.id,
            },
            orgId,
            divId: boardSelected?.divId,
            boardId: boardSelected.id,
          })
            .then(() => {
              notification.success({
                message: intl.formatMessage({ id: 'general.save.successful.message' }),
              });
              onRefreshCharts(true);
              onCloseMainDrawer();
            })
            .finally(() => {
              setLoading(false);
            });
        },
      };

      action[name]();
    },
    [boardSelected?.divId, boardSelected.id, intl, onRefreshCharts, orgId],
  );

  const handleDeleteBoard = async () => {
    setLoading(true);
    await onDeleteBoard();
    setLoading(false);
  };

  const METRICS_COMPONENTS = {
    [COMPONENTS_OF_METRICS.CHART]: currentElementSelected.current.chart?.id ? (
      <ChartEdit
        chart={currentElementSelected.current.chart}
        divId={boardSelected?.divId}
        boardId={boardSelected?.id}
        formRef={chartFormRef}
      />
    ) : (
      <EventsForm divId={boardSelected?.divId} formRef={chartFormRef} />
    ),
    [COMPONENTS_OF_METRICS.TABLE]: (
      <TableForm
        tableId={currentElementSelected.current.table}
        divId={boardSelected?.divId}
        boardId={boardSelected?.id}
        formRef={tableFormRef}
      />
    ),
  };

  const submitAction = {
    [COMPONENTS_OF_METRICS.CHART]: () => chartFormRef.current?.submit(),
    [COMPONENTS_OF_METRICS.TABLE]: () => tableFormRef.current?.submit(),
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <SlidePanel />
      <BoxContainer className={slidePanelOpened && !isMobile && styles.auxBySlidePanel}>
        <BoxContainer content shadow fixed>
          <FilterContainer
            description={
              <IntlMessages
                id={makeSubtitle({
                  isRelative: filters.relative.isRelative,
                  dateType: filters.relative.dateType,
                })}
                values={{
                  amount: filters.relative.relativeAmount,
                  groupBy: intl.formatMessage(
                    { id: `dateTypes.${filters.groupBy}` },
                    { amount: 1 },
                  ),
                }}
              />
            }
            showHide
            title={<Title value={boardSelected?.name} />}
            content={
              <ChartFilters
                handleFilters={handleFilters}
                onRefreshCharts={onRefreshCharts}
                filters={filtersMemo}
                pauseTime={hasChanges || someChartTypeSelected}
              />
            }
            buttonItems={[
              {
                type: 'danger',
                iconName: 'delete',
                action: handleDeleteBoard,
                disabled: !hasBoardSelected,
                allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.DELETE,
              },
              {
                iconName: 'edit',
                type: 'secondary',
                disabled: !hasBoardSelected,
                allowedRole: [
                  ...ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.CREATE,
                  ...ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.DASHBOARDS.CHARTS.CREATE,
                ],
                action: onOpenBoardFormEdit,
              },
              {
                action: () => {
                  setCreatingChart(true);
                  runGetAllSchedules(); // NOTE: this is to get the schedules from the context
                },
                hidden: !isMobile && creatingChart,
                allowedRole: [
                  ...ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.CREATE,
                  ...ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.DASHBOARDS.CHARTS.CREATE,
                ],
                disabled: !hasBoardSelected,
                iconName: 'add',
                type: 'secondary',
              },
              {
                action: saveLayout,
                type: 'secondary',
                allowedRole: [
                  ...ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.CREATE,
                  ...ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.DASHBOARDS.CHARTS.CREATE,
                ],
                hidden: !hasChanges,
                loading: savingLayoutChanges,
                disabled: !hasBoardSelected,
                iconName: 'save',
              },
            ]}
          />
        </BoxContainer>
        <BoxContainer loading={mainLoading}>
          <ResponsiveGridLayout
            isDraggable={!isMobile}
            isResizable={!isMobile}
            className="layout"
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            onLayoutChange={checkLayoutChange}
            draggableCancel=".cancelDrag"
          >
            {charts.length > 0 &&
              charts.map(chart => (
                /* Dev: DO NOT move the div with data-grid inside the maped component,
          it throws an error on running time about references.
        */
                <div
                  key={chart.id}
                  data-grid={{ ...chart.layout, minW: 2, minH: 2, maxH: 7 }}
                  className="gx-d-flex"
                >
                  <ChartCard
                    filters={filters}
                    refresh={refreshChart}
                    chart={chart}
                    onEdit={onEdit}
                  />
                </div>
              ))}

            {tables.length > 0 &&
              tables.map(table => (
                <div
                  key={table.id}
                  data-grid={
                    isMobile
                      ? {
                          x: 0,
                          y: 0,
                          w: 4,
                          h: tableElementsRef.current[table?.id] || 4.3,
                          minH: 2,
                          maxH: 4.3,
                        }
                      : { ...table.layout, minW: 2, minH: 2, maxH: 7 }
                  }
                  className="gx-d-flex"
                >
                  <TableCard
                    table={table}
                    refresh={refreshChart}
                    filters={filters}
                    eventTypes={eventTypes}
                    onEdit={onEdit}
                    onDelete={() => {
                      onDeleteTable({
                        id: table.id,
                        orgId,
                        divId: boardSelected?.divId,
                        boardId: boardSelected.id,
                      });
                      onRefreshCharts(true);
                    }}
                    elementsRef={tableElementsRef}
                  />
                </div>
              ))}
          </ResponsiveGridLayout>
          {mainLoading && <Spin />}
          {!charts.length && !tables.length && (
            <Empty description={<IntlMessages id="components.empty.description" />} />
          )}
        </BoxContainer>
        <BoardForm filters={filters} />
        <Drawer open={creatingChart} onClose={onCloseMainDrawer} closable={false} footer={null}>
          <BoxContainer>
            <BoxContainer content loading={loading}>
              <FilterContainer
                goBack={onCloseMainDrawer}
                title={
                  <Title.Header
                    value={
                      someChartTypeSelected ? (
                        <IntlMessages id="dashboards.component.edit" />
                      ) : (
                        <IntlMessages id="dashboards.component.new" />
                        // eslint-disable-next-line react/jsx-indent
                      )
                    }
                  />
                }
                content={
                  someChartTypeSelected ? null : (
                    <Select
                      value={metricsComponenteSelected}
                      onChange={setMetricsComponenteSelected}
                      className="gx-w-100 gx-m-0"
                      options={COMPONENTS_OF_METRICS_OPTIONS}
                      disabled={!hasBoardSelected || someChartTypeSelected}
                    />
                    // eslint-disable-next-line react/jsx-indent
                  )
                }
                buttonItems={[
                  {
                    action: () => submitAction[metricsComponenteSelected](),
                    iconName: 'save_as',
                    disabled: loading || !metricsComponenteSelected,
                  },
                ]}
              />
            </BoxContainer>
            <BoxContainer content>
              {metricsComponenteSelected && METRICS_COMPONENTS[metricsComponenteSelected]}
            </BoxContainer>
          </BoxContainer>
        </Drawer>
      </BoxContainer>
    </Form.Provider>
  );
};

export default EventsDashboard;
