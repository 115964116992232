import { lazy } from 'react';
import { union } from 'lodash';

import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';

const LocationsList = lazy(() => import('./screens/LocationsList'));
const GpsLocation = lazy(() => import('./screens/GpsLocation'));
const NfcLocation = lazy(() => import('./screens/NfcLocation'));
const QrLocation = lazy(() => import('./screens/QrLocation'));
const UploadLocationFiles = lazy(() => import('./screens/UploadFiles'));
const LocationGroup = lazy(() => import('./screens/LocationGroup'));
const GroupLocations = lazy(() => import('./screens/GroupLocations'));

export default [
  {
    component: LocationsList,
    path: PATHS.BASE_URL,
    exact: true,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.LIST,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.LIST,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: GpsLocation,
    path: PATHS.CREATE_GPS,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: GpsLocation,
    path: PATHS.EDIT_GPS,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.UPDATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.UPDATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: NfcLocation,
    path: PATHS.CREATE_NFC,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: NfcLocation,
    path: PATHS.EDIT_NFC,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.UPDATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.UPDATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: QrLocation,
    path: PATHS.CREATE_QR,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: QrLocation,
    path: PATHS.EDIT_QR,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.UPDATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.UPDATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: UploadLocationFiles,
    path: PATHS.UPLOAD_FILES,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: LocationGroup,
    path: PATHS.LOCATION_GROUP,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATION_GROUPS.CREATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATION_GROUPS.CREATE,
    ),
  },
  {
    component: LocationGroup,
    path: PATHS.EDIT_LOCATION_GROUP,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATION_GROUPS.UPDATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATION_GROUPS.UPDATE,
    ),
  },
  {
    component: GroupLocations,
    path: PATHS.GROUP_LOCATIONS,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATION_GROUPS.LIST,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATION_GROUPS.LIST,
    ),
  },
];
