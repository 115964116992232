import { auth, db } from 'firebase/firebase';
import { collection, doc } from 'firebase/firestore';
import { convertDateToMinutes } from 'packages/utils';
import { addOrderToTriggers, removeOrderFromTriggers } from 'packages/utils/functions/triggers';
import { PATHS as WORK_ORDER_PATHS } from 'packages/workOrders/constants';
import CustomError from 'util/customError';

export const BASE_URL = 'work_templates';

export const PATHS = {
  BASE_URL,
  CREATE_TEMPLATE: `${BASE_URL}/create`,
  EDIT_TEMPLATE: `${BASE_URL}/template/:divId/:templateId`,
};

export const FORM_ITEM_LAYOUT = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 24,
  },
};

export const FORMS = {
  WORK_TEMPLATE: 'workTemplate',
  FORMS: 'forms',
  TRIGGERS: 'triggers',
};

export const COMPONENTS_NOT_ALLOWED = ['file', 'video', 'photo'];
export const MAX_FORMS = 5;
export const MAX_TEMPLATE_TRIGGERS = 20;
export const PAGE_SIZE = 50;

export const getRouteToCreateWorkTemplate = () => PATHS.CREATE_TEMPLATE;

export const getRouteToEditWorkTemplate = (divId, templateId) =>
  PATHS.EDIT_TEMPLATE.replace(':divId', divId).replace(':templateId', templateId);

export const getRouteToCreateWorkOrder = (divId, templateId) =>
  WORK_ORDER_PATHS.CREATE_ORDER_BY_TEMPLATE.replace(':divId', divId).replace(
    ':templateId',
    templateId,
  );

export const factoryWorkTemplate = ({ data, divisionId, forms = [], triggers = [] }) => {
  try {
    const {
      name,
      startDateOptional,
      startTimeOptional,
      duration,
      durationReadonly,
      timezone,
      timezoneReadonly,
      minUsers,
      isExpiredAllowed,
      expiredAllowedTime,
      triggersFollowIndex,
    } = data;

    // add order to triggers
    let triggersParsed = triggers;
    if (triggersFollowIndex) {
      triggersParsed = addOrderToTriggers(triggers);
    } else {
      triggersParsed = removeOrderFromTriggers(triggers);
    }

    return {
      forms,
      name,
      startDateOptional,
      startTimeOptional,
      duration,
      durationReadonly,
      triggers: triggersParsed,
      divId: divisionId,
      timezone,
      timezoneReadonly,
      minUsers,
      allowedGroups: [],
      isExpiredAllowed,
      expiredAllowedTime: convertDateToMinutes(expiredAllowedTime),
      triggersFollowIndex,
    };
  } catch (error) {
    throw new CustomError(error?.message, error?.code);
  }
};

export const factoryTriggerToWorkTemplateExisting = data =>
  data.map(item => ({
    ...item,
    locationOptional: true,
    locationRequired: false,
    expectedExecutions: 1,
  }));

export const factoryTriggerToWorkTemplate = (data, divisionId) => {
  const { cooldown, description, eventType, name, location, locationOptional, locationRequired } =
    data || {};

  const locationData = location?.data;
  const {
    title: eventTypeTitle,
    key: eventTypeKey,
    label: eventTypeLabel,
    disabled: eventTypeDisabled,
    value: eventTypeValue,
    ...newEventType
  } = eventType;

  const cooldownInMinutes = cooldown ? convertDateToMinutes(cooldown) : null;

  const uniqueId = doc(collection(db, 'triggers'));

  const triggerBody = {
    avoidConsecutiveTriggering: false,
    cooldown: cooldownInMinutes,
    description,
    eventType: newEventType,
    id: uniqueId.id,
    name,
    locationOptional,
    locationRequired,
    uid: auth.currentUser.uid,
    divId: divisionId,
    expectedExecutions: 1,
  };

  if (location?.isAGroup) {
    triggerBody.locationGroup = locationData;
  } else {
    triggerBody.locations = locationData || [];
  }

  return triggerBody;
};

export const mergeTriggers = (triggers, newTriggers) =>
  triggers.reduce((acc, item) => {
    const existingItem = acc.find(
      i => (i?.triggerId && i?.triggerId === item?.triggerId) || i?.id === item?.id,
    );
    if (!existingItem) {
      acc.push({
        ...item,
      });
    }
    return acc;
  }, newTriggers);
