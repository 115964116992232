import { lazy } from 'react';
import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';

const TriggersList = lazy(() => import('./screens/TriggersList'));
const Trigger = lazy(() => import('./screens/Trigger'));

export default [
  {
    component: TriggersList,
    path: PATHS.BASE_URL,
    exact: true,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: Trigger,
    path: PATHS.CREATE_TRIGGER,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.CREATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: Trigger,
    path: PATHS.EDIT_TRIGGER,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.UPDATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
];
