/* eslint-disable no-case-declarations */
import { doc, setDoc, collection, deleteDoc } from 'firebase/firestore';
import { auth, db, serverTimestamp } from 'firebase/firebase';
import { isDev } from 'constants/commons';

export const createOperation = async (
  collectionName,
  documentId,
  dataToSave,
  method = 'create',
  idAsMainKey = false,
  forceUpdate = true,
) => {
  try {
    if (!['create', 'update', 'delete'].includes(method.toLowerCase())) {
      throw new Error('Invalid method. Supported methods are: create, update, delete');
    }

    const collectionRef = collection(db, collectionName);

    let newDocData = dataToSave;
    let output = {};

    const auditFields = {
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      uid: auth?.currentUser?.uid,
    };

    // eslint-disable-next-line default-case
    switch (method.toLowerCase()) {
      case 'create':
        const newDocRef = idAsMainKey ? doc(collectionRef, documentId) : doc(collectionRef);
        newDocData = {
          ...dataToSave,
          id: newDocRef.id, // New Doc Id
          ...auditFields,
        };
        output = newDocData;
        await setDoc(newDocRef, newDocData);
        break;
      case 'update':
        if (!documentId) {
          throw new Error('Document ID is required for update operation');
        }
        newDocData = {
          ...dataToSave,
        };
        if (!forceUpdate) {
          newDocData = {
            ...newDocData,
            uid: auth?.currentUser?.uid,
            updatedAt: serverTimestamp(),
          };
        }
        output = {
          ...newDocData,
          id: documentId,
        };
        await setDoc(doc(collectionRef, documentId), newDocData, { merge: forceUpdate });
        break;
      case 'delete':
        if (!documentId) {
          throw new Error('Document ID is required for delete operation');
        }
        newDocData = {
          id: documentId,
        };
        await deleteDoc(doc(collectionRef, documentId));
        break;
    }
    if (isDev) {
      // eslint-disable-next-line no-console
      console.info(`Successfully ${method}d data in collection: ${collectionName}`);
    }
    return Promise.resolve(output);
  } catch (error) {
    if (isDev) {
      // eslint-disable-next-line no-console
      console.log('Operation error', error);
    }
    return Promise.reject(error);
  }
};
