import { lazy } from 'react';
import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';

const WorkTemplate = lazy(() => import('./screens/WorkTemplate'));
const WorkTemplateList = lazy(() => import('./screens/WorkTemplateList'));

export default [
  {
    component: WorkTemplateList,
    path: PATHS.BASE_URL,
    exact: true,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_TEMPLATES.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: WorkTemplate,
    path: PATHS.CREATE_TEMPLATE,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_TEMPLATES.CREATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: WorkTemplate,
    path: PATHS.EDIT_TEMPLATE,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_TEMPLATES.UPDATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
];
