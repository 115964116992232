import React from 'react';
import { useSelector } from 'react-redux';

function DivisionIconLabel({ includeIcon = false, divId }) {
  const orgId = useSelector(state => state?.organizations?.organization?.id);
  const division = useSelector(
    ({ user: userState }) => userState?.profile?.data?.organizations?.[orgId]?.divisions?.[divId] || {}, 
  );

  return (
    <div className="gx-flex-row  gx-align-items-center">
      {includeIcon && (
        <div className="gx-fs-md">
          <span class="material-symbols-outlined gx-mr-1 gx-fs-11">map</span>
        </div>
      )}
      <div className="gx-fs-sm gx-text-grey">{`(${division?.name || ''})`}</div>
    </div>
  );
}

export default React.memo(DivisionIconLabel);
