import React from 'react';

import { createRoot } from 'react-dom/client';
import * as unRegisterListenerAuth from 'appRedux/sagas/user/token';
import * as OfflinePluginRuntime from '@lcdp/offline-plugin/runtime';
import * as unRegisterListenerUser from 'appRedux/sagas/user/profile';
import { browserTracingIntegration, init, replayIntegration } from '@sentry/browser';

import NextApp from './NextApp';
import { firebaseApp } from './firebase/firebase';
import registerServiceWorker from './registerServiceWorker';

const container = document.getElementById('root');
const root = createRoot(container);

const render = Component => {
  root.render(<Component />);
};

const initializeServiceWorker = () => {
  registerServiceWorker();
};

const initializeSentry = environment => {
  init({
    environment,
    dsn: 'https://accb4b320f9247ed92ff755fa4f44fc3@o436503.ingest.sentry.io/5755449',
    release: `${SYSTEM.version}`,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [browserTracingIntegration(), replayIntegration()],
    tracesSampleRate: 1.0,
  });
};

const initializeOfflinePlugin = loadTime => {
  OfflinePluginRuntime.install({
    onUpdateReady() {
      OfflinePluginRuntime.applyUpdate();
    },
    onUpdated() {
      const autoUpdate = Date.now() - loadTime <= 7000;
      if (
        autoUpdate ||
        // eslint-disable-next-line no-alert
        window.confirm('There is a new version of the app. Do you want to reload?')
      ) {
        window.location.reload();
      }
    },
  });
};

const handleHotModuleReplacement = () => {
  if (module.hot) {
    module.hot.accept('./NextApp', () => {
      unRegisterListenerUser.listenerUnregister()();
      unRegisterListenerAuth.listenerUnregister()();
      render(NextApp);
    });
  }
};

const initializeApp = () => {
  const environment = firebaseApp?.options?.projectId;

  initializeServiceWorker();
  render(NextApp);

  if (module.hot) {
    handleHotModuleReplacement();
  } else {
    initializeSentry(environment);
    const loadTime = Date.now();
    initializeOfflinePlugin(loadTime);
  }
};

initializeApp();
