import { lazy } from 'react';
import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';

const UsersList = lazy(() => import('./screens/UsersList'));
const UserPermissions = lazy(() => import('./screens/UserPermissions'));
const InviteUsers = lazy(() => import('./screens/InviteUsers'));
const AcceptInvitation = lazy(() => import('./screens/AcceptInvitation'));
const CreateUser = lazy(() => import('./screens/CreateUser'));
const UserNotifications = lazy(() => import('./screens/Notifications'));

export default [
  {
    component: UsersList,
    path: PATHS.BASE_URL,
    exact: true,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.USERS.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: UserPermissions,
    exact: true,
    path: PATHS.USER_PERMISSIONS,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.USERS.UPDATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: InviteUsers,
    path: PATHS.USERS_INVITE,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.INVITATIONS.CREATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: AcceptInvitation,
    path: PATHS.ACCEPT_INVITATION,
    onOrgChangeRedirectTo: '',
  },
  {
    component: CreateUser,
    path: PATHS.CREATE_USER,
    onOrgChangeRedirectTo: '',
  },
  {
    component: UserNotifications,
    path: PATHS.USER_NOTIFICATIONS,
    onOrgChangeRedirectTo: '',
  },
];
