import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Select, Switch } from 'antd';

import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import { KeyValueWidget } from 'components/KeyValueWidget';
import Title from 'components/BoxContainer/components/Title';

import styles from './styles.module.less';
import SubTitle from '../../../../../../components/Subtitle';
import LabelValue from '../../../../../../components/LabelValue';
import { CHART_TYPES, DASHBOARDS_FORMS, defaultAggsConfig } from '../../constants';
import { aggIntl, aggOptions, aggregationFactory, DEFAULT_CHART_TYPE } from './constants';
import SimpleColorPicker from '../../../../../../components/ColorPicker/SimpleColorPicker';

const ChartEdit = ({ chart, formRef }) => {
  const aggregations = chart?.aggregations;
  const { avg, count, max, min, sum } = aggregations || {};

  const [title, setTitle] = useState(chart?.title);
  const [selectedAggs, setSelectedAggs] = useState(chart?.aggregations);

  const aggregationsOn = Object.keys(selectedAggs).map(e => e);

  const colorInitValues = chart
    ? {
        min: min?.color,
        max: max?.color,
        avg: avg?.color,
        sum: sum?.color,
        count: count?.color,
      }
    : {};

  const chartTypesInitValues = chart
    ? {
        min: min?.displayType,
        max: max?.displayType,
        avg: avg?.displayType,
        sum: sum?.displayType,
        count: count?.displayType,
      }
    : {};
  const [color, setColor] = useState(colorInitValues);
  const [chartTypes, setChartTypes] = useState(chartTypesInitValues);

  const CHART_TYPE_OPTIONS = CHART_TYPES.map(ct => ({
    label: <IntlMessages id={`dashboards.chartType.form.${ct}.option`} />,
    value: ct,
  }));

  const handleChartType = (agregationSelected, chartTypeSelected) => {
    setChartTypes({
      ...chartTypes,
      [agregationSelected]: chartTypeSelected,
    });
  };
  const handleColor = (agregationSelected, rgbValue) => {
    setColor({
      ...color,
      [agregationSelected]: rgbValue,
    });
  };

  const toggleOption = (isOn, opt) => {
    if (isOn) {
      setSelectedAggs(curr => ({ ...curr, [opt]: defaultAggsConfig[opt] }));

      handleChartType(opt, defaultAggsConfig[opt]?.displayType);
      handleColor(opt, defaultAggsConfig[opt]?.color);
    } else {
      setSelectedAggs(({ [opt]: remove, ...rest }) => ({ ...rest }));

      handleChartType(opt, null);
      handleColor(opt, null);
    }
  };

  const handleTitleChange = ({ target: { value } }) => {
    setTitle(value);
  };

  // NOTE: Updating values in the form ref since these values will be used in the submit function
  useEffect(() => {
    const newAggregations = aggregationFactory(selectedAggs, color, chartTypes);
    const body = {
      ...chart,
      title,
      aggregations: newAggregations,
    };
    formRef.current.setFieldValue('chart', body);
  }, [chart, chartTypes, color, formRef, selectedAggs, title]);

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 24 }}
      ref={formRef}
      name={DASHBOARDS_FORMS.CHART}
    >
      <Form.Item hidden name="chart" />
      <BoxContainer>
        <BoxContainer content>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <KeyValueWidget
                label={
                  <Title.LabelForm
                    required
                    value={<IntlMessages id="dashboards.chart.form.title" />}
                  />
                }
                value={
                  <Form.Item
                    rules={[{ required: true, message: <IntlMessages id="form.required" /> }]}
                    name="title"
                    initialValue={title}
                  >
                    <Input onChange={handleTitleChange} value={title} />
                  </Form.Item>
                }
              />
            </Col>
          </Row>
          <div className="gx-d-flex gx-flex-column">
            <SubTitle
              text={
                <Title.LabelForm value={<IntlMessages id="dashboards.chart.form.aggregations" />} />
              }
            />
            <Row gutter={8} className="gx-ml-2">
              {aggOptions.length > 0 &&
                aggOptions?.map(opt => (
                  <div className="gx-w-100" key={opt}>
                    <Row gutter={8}>
                      <Col xs={6} xl={2} xxl={2}>
                        <LabelValue
                          vertical
                          name={<IntlMessages id={aggIntl[opt]} />}
                          value={
                            <Switch
                              onChange={val => toggleOption(val, opt)}
                              checked={!!selectedAggs[opt]}
                            />
                          }
                        />
                      </Col>
                      <Col xs={14} xl={20} xxl={20}>
                        <LabelValue
                          vertical
                          name={<IntlMessages id="dashboards.chartType.form.title" />}
                          value={
                            <Select
                              defaultValue={DEFAULT_CHART_TYPE}
                              disabled={!aggregationsOn.includes(opt)}
                              value={chartTypes[opt]}
                              className="gx-w-100"
                              options={CHART_TYPE_OPTIONS}
                              onChange={val => handleChartType(opt, val)}
                            />
                          }
                        />
                      </Col>
                      <Col className={styles.colorPickerContainer} xs={4} xl={2} xxl={2}>
                        <SimpleColorPicker
                          onChange={newRgB => handleColor(opt, newRgB)}
                          color={color[opt] || defaultAggsConfig[opt].color}
                          hexColor
                          key={opt}
                          disabled={!aggregationsOn.includes(opt)}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
            </Row>
          </div>
        </BoxContainer>
      </BoxContainer>
    </Form>
  );
};

ChartEdit.propTypes = {
  chart: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    aggregations: PropTypes.shape({
      avg: PropTypes.shape({
        color: PropTypes.string,
        displayType: PropTypes.string,
      }),
      count: PropTypes.shape({
        color: PropTypes.string,
        displayType: PropTypes.string,
      }),
      max: PropTypes.shape({
        color: PropTypes.string,
        displayType: PropTypes.string,
      }),
      min: PropTypes.shape({
        color: PropTypes.string,
        displayType: PropTypes.string,
      }),
      sum: PropTypes.shape({
        color: PropTypes.string,
        displayType: PropTypes.string,
      }),
    }),
    createdAt: PropTypes.object,
    updatedAt: PropTypes.object,
  }),
  formRef: PropTypes.object,
};

export default ChartEdit;
