import { lazy } from 'react';
import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';

const Timeline = lazy(() => import('./screens/Timeline'));
const EventDetail = lazy(() => import('./screens/EventDetail'));
const TempEventForm = lazy(() => import('./screens/TempEventForm'));

export default [
  {
    component: Timeline,
    path: PATHS.BASE_URL,
    exact: true,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.EVENTS_SUMMARY.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: EventDetail,
    path: PATHS.EVENT_DETAIL,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.EVENTS_SUMMARY.GET,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: TempEventForm,
    path: PATHS.TEMP_CREATE,
    onOrgChangeRedirectTo: '/:orgId/-',
  },
];
